<template>
  <div class="page">
    <el-collapse-transition>
    <div class="location-box" style="text-align: center;" v-show="loading">
      <span style="color:white">{{describe}}</span>
    </div>
  </el-collapse-transition>

    <!-- <div class="device-box-title" style="text-align: center; color: white" @click="deviceShow = !deviceShow">
      <span v-if="deviceShow == true">收起</span>
      <span v-if="deviceShow == false">展开</span>
    </div> -->
    <el-collapse-transition>
      <div class="device-box" v-show="deviceShow">
        <div class="device-box-top">
          <div class="device-title">
            <span style="margin-left: 10px">实时定位</span>
            <el-button plain style="margin-right: 10px; color: #007ffe" @click="addDevice">添加设备</el-button>
          </div>
        </div>

        <div>
          <el-table :data="dataList" :show-header="false"  style="width: 100%" :empty-text="emptyText">
            <el-table-column label="设备"  label-class-name="tablehead" align="center" >
              <template #default="scope">
                <div style="width: 100%; height: 50px">
                  <div style="display: flex; height: 60%; font-size: 14px">
                    <span style="align-self: center">设备编号:</span>
                    <span style="align-self: center">{{scope.row.deviceId}}</span>
                  </div>
                  <div style="display: flex; height: 40%; font-size: 10px; color: #8a8a8a;" >
                    <span style="align-self: center">设备名称:</span>
                    <span style="align-self: center">{{ scope.row.deviceName}}</span>
                    <span>&emsp;&emsp;</span>
                    <span style="align-self: center">设备型号:</span>
                    <span style="align-self: center">{{ scope.row.deviceModel}}</span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="操作" label-class-name="tablehead" align="center" width="100">
              <template #default="scope">
                <el-image style="width: 30px; height: 30px;cursor: pointer;" @click="location(scope.row)" :src="require('../../../assets/img/location.png')"></el-image>
                <el-image style="margin-left: 5px; width: 30px; height: 30px;cursor: pointer;" @click="deleteDevice(scope.row)" :src="require('../../../assets/img/delete.png')"></el-image>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-collapse-transition>

    <div class="box">
      <div id="map" class="amap-wrapper"></div>
    </div>
  </div>
  <template v-if="devicePage">
    <LocationAddWear
      @update="updateDevice"
      @cancel="this.devicePage = false"
    ></LocationAddWear>
  </template>
</template>

<script>
// import markerContent from './marker-content'
import {
  getSgWearInfos,
  deleteSgWearInfo,
  synWearInfo,
  getSgInfos
} from "../../../api/api";
import session from "../../../store/store";
import mapInfo from './js/map-info'
import AMap from "AMap"; // 引入高德地图
import LocationAddWear from "./LocationAddWear.vue";
export default {
  components: {
    LocationAddWear,
  },
  data() {
    return {
      emptyText: "加载中",
      deviceShow: true,
      devicePage: false,
      loading:false,
      infoWindow: {}, //窗体
      map: {}, //地图实例对象
      iconBlue: {},
      dataList: [],
      deviceId: "",
      sgData:{},
      deviceData:{},
      describe:'',
      timer:null,
      count:0,
      nowTime:null,
    };
  },
  mounted() {
    this.initMap();
    this.init();
  },
  unmounted() {
    // 在页面销毁后，清除计时器
    this.closeInterval();
    if(this.map != null) {
      this.map.destroy()
      this.map = null
    }
  },
  methods: {
    init() {
      this.getSgWearInfos();
    },
    updateDevice() {
      this.getSgWearInfos();
    },
    getSgWearInfos() {
      let param = {
        unitUuid: session.getLoginInfo().unitUuid,
        page: 0,
        size: 5,
      };
      getSgWearInfos(param).then((res) => {
        if (res.data.code == 200) {
          this.dataList = res.data.dataInfo;
        } else {
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据";
        }
      });
    },

    addDevice() {
      if(this.dataList.length == 5) {
        this.$message.error({ message: "最多只能添加5个"});
        return
      }
      this.devicePage = true;
    },

    /**
     * 地图初始化
     */
    initMap() {
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        zoom: 17,
        pitch: 50,
        // viewMode: "3D", //开启3D视图,默认为关闭
      });
      this.infoWindow = new AMap.InfoWindow({
        // isCustom: true,  //使用自定义窗体
        offset: new AMap.Pixel(7, 0),
      });
      
      this.iconBlue = new AMap.Icon({
        image: require("../../../assets/img/point-blue.png"),
        size: new AMap.Size(14, 23),
      });
    },

    addMarker() {
      //实时路况图层
      this.map.clearMap();
      var marker = new AMap.Marker({
        position: new AMap.LngLat(this.sgData.lng, this.sgData.lat),//位置
        icon: this.iconBlue, // 添加 Icon 实例
      })
      this.map.add(marker);//添加到地图

      this.infoWindow.setContent(mapInfo.getHtml(this.deviceData,this.sgData));
      marker.on('click', () => {
        if(!this.infoWindow.getIsOpen()) {
            this.infoWindow.open(this.map, marker.getPosition());
        }else {
          this.infoWindow.close();
        }
      });

      this.infoWindow.open(this.map, marker.getPosition());
      this.map.setFitView(marker,true);
    },

    location(row) {
      if(this.count > 30) {
        this.count = 0
      }else if(this.count != 0) {
        this.$message.error({ message: '正在定位中' });
        return;
      }
      this.count = 0
      this.nowTime = new Date().getTime()
      this.deviceData = row
      this.deviceId = row.deviceId;
      let param = {
        deviceId: row.deviceId,
        func: "location",
      };
      synWearInfo(param).then((res) => {
        if (res.data.code == 200) {
          // this.$message.success({ message: "定位请求已发出" });
          this.loading = true;
          this.describe = "定位中"
          this.setInterval();
        } else {
          this.$message.error({ message: res.data.msg });
        }
      });
    },

    setInterval() {
      
      this.closeInterval();
      this.timer = setInterval(() => {
          this.getSgInfos()
      }, 10*1000);
    },

    closeInterval() {
      clearInterval(this.timer); //清除计时器
      this.timer = null; //设置为null
    },

    getSgInfos() {
      if(this.count > 30) {
        this.describe = '定位失败,已超时'
        this.closeInterval();
        this.count = 0
        return;
      }
      this.count++
      let param = {
        deviceId: this.deviceId,
        page: 0,
        size: 1,
      }
      getSgInfos(param).then(res => {
        if (res.data.code == 200) {
          var sgDataList = res.data.dataInfo;
          this.sgData = sgDataList[0];

          //判断定位时间与当前时间进行比较
          var sgtime = new Date(this.sgData.createTime).getTime()
          // var nowtime = new Date().getTime()
          console.log(sgtime,this.nowTime)
          // 活动时间小于当前时间，不能报名
          if (sgtime >= this.nowTime) {
            this.count = 0
            this.describe = '定位成功'
            this.closeInterval()
            if(0 == this.sgData.mode) {
              this.sgData.modeName = 'GPS'
            }else if(1 == this.sgData.mode) {
              this.sgData.modeName = 'LBS'
            } else{
              this.sgData.modeName = 'WIFI'
            }
              this.addMarker();
          }

      
        } 
      })
    },


    deleteDevice(row) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var param = {
            unitUuid: session.getLoginInfo().unitUuid,
            deviceId: row.deviceId,
          };
          deleteSgWearInfo(param).then((res) => {
            if (res.data.code == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.getSgWearInfos();
            } else {
              this.$message.error({ message: res.data.message });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.device-box-title {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  background-color: #007ffe;
  padding: 9px 0px;
  border-radius: 8px;
  width: 330px;
  height: 20px;
}
.device-box {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  background-color: white;
  /* padding: 9px; */
  border-radius: 8px;
  width: 330px;
  /* height: 500px; */
}

.device-box-top {
  width: 100%;
  height: 60px;
  background-color: #007ffe;
  border-radius: 8px 8px 0px 0px;
  /* display: flex;
  align-items: center; */
}

.device-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 18px;
}

.box {
  width: 100%;
  height: 100%;
  /* height: 900px; */
  position: fixed;
}
.amap-wrapper {
  width: 100%;
  height: 100%;
}

.location-box {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
  background-color: #007ffe;
  padding: 9px 0px;
  border-radius: 8px;
  width: 330px;
  height: 20px;
}

</style>
<style>


/* .device-box .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: white;
} */
.amap-info-content {
    overflow: hidden;
    padding: 0px;
  }

</style>
